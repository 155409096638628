import * as React from 'react';
import { st, classes, cssStates } from './Grid.st.css';
import { getGridStyle, getMediaQueries, itemsPerRowWidth } from './GridUtils';
import {
  COLUMN_GAP,
  DEFAULT_MIN_WIDTH,
  MOBILE_COLUMN_GAP,
  MOBILE_ROW_GAP,
  ROW_GAP,
} from './constants';
import { GridDataKeys, GridDataHooks } from './DataHooks';
import { Item } from './Item/Item';
import { GridDefaultProps, GridProps, GridStyleProps } from './types';

function generateKey(prefix = 'prefix'): string {
  return `${prefix}_${Math.random().toString(36).substr(2, 9)}`;
}

export class Grid extends React.PureComponent<GridProps> {
  static displayName = 'Grid';
  static Item = Item;

  static defaultProps: GridDefaultProps = {
    children: [],
    showRowDivider: false,
    maxColumns: 1,
    width: 0,
    dividerWidth: '1px',
    uniformRowHeight: true,
    minColumnWidth: DEFAULT_MIN_WIDTH,
  };

  private getContainerDataAttributes({
    itemsPerRow,
    itemMaxWidth,
    dividerWidth,
    rowGapInPixels,
    columnGapInPixels,
    maxColumnWidth,
    minColumnWidth,
  }: any) {
    return {
      [GridDataKeys.itemsPerRow]: itemsPerRow,
      [GridDataKeys.itemMaxWidth]: itemMaxWidth,
      [GridDataKeys.dividerWidth]: dividerWidth,
      [GridDataKeys.rowGap]: rowGapInPixels,
      [GridDataKeys.columnGap]: columnGapInPixels,
      [GridDataKeys.maxColumnWidth]: maxColumnWidth,
      [GridDataKeys.minColumnWidth]: minColumnWidth,
    };
  }

  createStyleElement(props: GridStyleProps) {
    const styleContent = `${getGridStyle({
      cssStateDivider: props.cssStateDivider,
      dividerWidth: props.dividerWidth,
      gridId: props.gridId,
      gridTemplateColumns: props.gridTemplateColumns,
      rowGap: props.rowGap,
      listWrapperClass: classes.listWrapper,
    })}
      ${
        props.isFullWidth
          ? getMediaQueries({
              columnGap: props.columnGap,
              gridId: props.gridId,
              maxColumns: props.maxColumns,
              maxColumnWidth: props.maxColumnWidth,
              minColumnWidth: props.minColumnWidth,
              ListItemClass: classes.listWrapper,
            })
          : ''
      }`;

    return React.createElement('style', { children: styleContent });
  }

  render() {
    const {
      showRowDivider,
      width,
      children,
      uniformRowHeight,
      minColumnWidth,
      maxColumns,
      className,
      id,
    } = this.props;
    const mobile = this.props.isMobile;
    const itemMinWidth = `${minColumnWidth}px`;
    const itemMaxWidth = this.props.maxColumnWidth
      ? `${this.props.maxColumnWidth}px`
      : '100vw';
    const rowGap =
      typeof this.props.rowGap === 'number'
        ? this.props.rowGap
        : mobile
        ? MOBILE_ROW_GAP
        : ROW_GAP;

    const columnGap =
      typeof this.props.columnGap === 'number'
        ? this.props.columnGap
        : mobile
        ? MOBILE_COLUMN_GAP
        : COLUMN_GAP;

    const dividerWidth =
      typeof this.props.dividerWidth === 'number'
        ? `${this.props.dividerWidth}px`
        : this.props.dividerWidth;
    const rowGapInPixels = showRowDivider
      ? `calc(${rowGap}px + ${dividerWidth})`
      : `${rowGap}px`;
    const columnGapInPixels = `${columnGap}px`;
    const isFullWidth = width === 0;
    const itemsPerRow = isFullWidth
      ? maxColumns
      : itemsPerRowWidth(width, minColumnWidth, maxColumns, columnGap);
    const gridTemplateColumns = isFullWidth
      ? ''
      : `repeat(${itemsPerRow}, minmax(${itemMinWidth}, ${itemMaxWidth}))`;

    const gridId = id || generateKey();
    const cssStateDivider = cssStates({ dividers: true });

    return (
      <div style={{ 'container-type': 'inline-size' } as any}>
        <div
          className={st(
            classes.root,
            { dividers: showRowDivider, uniformRowHeight },
            className,
          )}
          {...this.getContainerDataAttributes({
            itemMaxWidth,
            itemsPerRow,
            dividerWidth,
            rowGapInPixels,
            columnGapInPixels,
            maxColumnWidth: itemMaxWidth,
            minColumnWidth: itemMinWidth,
          })}
          data-hook={GridDataHooks.container}
          id={gridId}
        >
          {this.createStyleElement({
            columnGap,
            cssStateDivider,
            dividerWidth,
            gridId,
            gridTemplateColumns,
            isFullWidth,
            maxColumns,
            maxColumnWidth: itemMaxWidth,
            minColumnWidth,
            rowGap,
          } as any)}

          <ul
            className={classes.listWrapper}
            style={{
              gridTemplateColumns,
              gap: `${rowGapInPixels} ${columnGapInPixels}`,
            }}
          >
            {children}
          </ul>
        </div>
      </div>
    );
  }
}
